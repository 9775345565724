/* ie hack */
header,
nav,
section,
article,
aside,
footer {
    display: block;
}

* {
    margin: 0;
    padding: 0;
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url('/fonts/Roboto-Bold.woff');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Roboto-Medium';
    src: url('/fonts/Roboto-Medium.woff');


    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('/fonts/Roboto-Regular.woff');


    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
     src: url('/fonts/Roboto-Light.woff');
    


    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'circeregular';
    // src: url('/S');
    src: url('/fonts/crc55-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/crc55-webfont.woff') format('woff'),
        url('/fonts/crc55-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

a,
body,
button,
div,
footer,
h1,
h2,
h3,
h4,
h5,
h6,
header,
img,
input,
label,
li,
main,
nav,
ol,
p,
section,
span,
table,
ul {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
}

body {
    font-family: 'circeregular', -serif !important;
    color: #000;
    background: white;
    line-height: normal;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-font-smoothing: antialiased;

    @media only screen and (max-device-width: 768px) {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media only screen and (min-device-width: 769px) {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: #fff;
            opacity: 0.6;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #0c7169;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }

        &::-moz-scrollbar {
            width: 8px;
            height: 30px;
            background-color: #eee;
            opacity: 0.6;
        }

        &::-moz-scrollbar-thumb {
            border-radius: 10px;
            background-color: #0c7169;
        }

        &::-moz-scrollbar-track {
            border-radius: 10px;
            background-color: #fff;
        }
    }
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}

.carousel-control-next,
.carousel-control-prev {
    width: 10% !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }

    @media screen and (max-width: 1023px) {
        display: none !important;
    }

    @media screen and (max-width: 1024px) and (max-height: 768px) {
        display: none !important;
    }
}

.carousel-indicators {
    @media screen and (max-width: 768px) {
        bottom: auto !important;
    }

    @media screen and (max-width: 1023px) {
        bottom: auto !important;
    }

    @media screen and (min-width: 1024px) and (min-height: 768px) {
        display: none !important;
    }
}

.carousel-indicators li {
    background-color: #000 !important;
}

.carousel-item {
    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }

    @media screen and (max-width: 1023px) {
        margin-top: 30px;
    }
}

ul li {
    list-style: none;
}

.h1,
h1 {
    font-size: 36px;
}

.h3,
h3 {
    font-size: 24px;
}

.h5,
h5 {
    font-size: 14px;
}

p {
    font-size: 14px;
}

.btn-connect {
    color: #fff;
    background-color: #539389;
    border-color: #539389;
    margin-left: 3rem;

    @media (max-width: 786px) {
        width: 100%;
        margin-left: 0;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
        background: #8f8f8f;
        color: #ffffff;
        border-color: #8f8f8f;
    }
}
